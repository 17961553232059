export const notificationShowAction = (state, value) => ({
  ...state,
  notification: {
    ...state.notification,
    ...value,
  },
});

export const notificationClearAction = (state) => ({
  ...state,
  notification: {
    color: '',
    text: '',
    global: true,
  },
});

export const authorizedAction = (state, value) => ({
  ...state,
  authorized: value,
});

export const hasExpiredLicenseAction = (state, value) => ({
  ...state,
  hasExpiredLicense: value,
});

export const personalLicensesAction = (state, value) => ({
  ...state,
  personalLicenses: value,
});

export const userAction = (state, value) => ({
  ...state,
  user: {
    ...state.user,
    ...value,
  },
});

export const isMobileDeviceAction = (state, value) => ({
  ...state,
  isMobileDevice: value,
});

export const downloadAction = (state, value) => {
  return {
    ...state,
    downloadLink: value,
  };
};

export const downloadActionClear = (state) => {
  return {
    ...state,
    downloadLink: null,
  };
};
