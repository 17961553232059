const initState = {
  notification: {
    color: '',
    text: '',
    global: true,
  },
  authorized: undefined,
  user: {
    name: '',
    email: '',
  },
  isMobileDevice: false,
  downloadLink: null,
  personalLicenses: [],
};

export { initState };
