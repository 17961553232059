import { useReducer, useContext, createContext, useMemo } from 'react';
import {
  notificationShowAction,
  notificationClearAction,
  authorizedAction,
  userAction,
  isMobileDeviceAction,
  downloadAction,
  downloadActionClear,
  hasExpiredLicenseAction,
  personalLicensesAction,
} from './actions';
import { initState } from './state';

const StateContext = createContext<any>(null);
const DispatchContext = createContext<any>(null);

const changeState = {
  notificationShowAction,
  notificationClearAction,
  authorizedAction,
  userAction,
  isMobileDeviceAction,
  downloadAction,
  downloadActionClear,
  hasExpiredLicenseAction,
  personalLicensesAction,
};

const reducer = (state, { type, value }) => {
  const fn = changeState[type];
  if (!fn) {
    return state;
  }
  return fn(state, value);
};

const createAction = (dispatch) => {
  const names = Object.keys(changeState);
  const actions = names.reduce((acc, item) => {
    return {
      ...acc,
      [item]: (value) => dispatch({ type: item, value }),
    };
  }, {});
  return actions;
};

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const actions = useMemo(() => createAction(dispatch), []);

  return (
    <DispatchContext.Provider value={actions}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export const useContextState = () => useContext(StateContext);
export const useContextDispatch = () => useContext(DispatchContext);
