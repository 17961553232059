import { appWithTranslation } from 'next-i18next';
import { IntercomProvider } from 'react-use-intercom';
import { useRouter } from 'next/router';
import { Provider } from '../components/context';

import '../style/style.css';
import '../style/tablet.css';
import '../style/desktop.css';
import '../style/intercom.scss';

import 'swiper/css';
import 'swiper/css/bundle';

const MyApp = ({ Component, pageProps }) => {
  const { pathname } = useRouter();

  return (
    <IntercomProvider key={JSON.stringify(pathname)} appId={'v3g3691o'}>
      <Provider>
        <Component {...pageProps} />
      </Provider>
    </IntercomProvider>
  );
};

export default appWithTranslation(MyApp);
